.l-auth {
  background-color: $cornflower-blue;
  color: $white;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
  min-height: 100vh;
  padding: 7vh 0;
  width: 100%;

  .content {
    max-width: 480px;
    padding: 20px;
  }
}
