.m-login-footer {
  text-align: center;

  .link {
    font-size: .8125rem;

    &:not(:first-child) {
      margin: 10px 0 0;

      @include media-breakpoint-up(md) {
        margin: 0 0 0 80px;
      }
    }

    @include media-breakpoint-up(md) {
      display: inline-block;
    }
  }
}
