.m-header {
  background-color: $cornflower-blue;
  position: relative;
  width: 100%;

  .nav-link {
    padding: 15px;
    text-align: left;

    @include media-breakpoint-up(md) {
      text-align: center;
    }
  }

  .important-links {
    margin-top: 3px;

    a {
      display: block;
      margin-left: 15px;
      padding: 10px;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .sign-out-link {
    display: none;
  }

  &.active {
    @include media-breakpoint-down(sm) {
      .navbar-toggler-icon {
        background-image: $white-hamburger-menu-icon;
      }

      .my-profile-link {
        color: $white;
      }

      .m-navbar {
        background-color: $cornflower-blue;
        box-shadow: 1px 1px 3px rgba($black, .1);
        display: block;
        width: 80%;
        z-index: 2;
      }

      .navbar-content {
        background-color: $cornflower-blue;
        box-shadow: 1px 1px 3px rgba($black, .1);
        display: block;
        flex-direction: column;
        height: calc(100vh - 50px);
        left: 0;
        overflow-y: scroll;
        position: absolute;
        padding-bottom: 15px;
        width: 100%;
        z-index: 1;
      }
    }
  }

  @include media-breakpoint-up(md) {
    background-color: transparent;
  }
}
