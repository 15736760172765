@mixin flex-center-justify {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: justify-content;

  @include media-breakpoint-up(sm) {
    flex-direction: row;
  }
}
