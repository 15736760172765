@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 200;
  src:
    local('TitilliumWeb ExtraLight'),
    local('TitilliumWeb-ExtraLight'),
    url(#{$theme-fonts-path}/TitilliumWeb-ExtraLight.ttf);
}

@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 200;
  src:
    local('TitilliumWeb ExtraLight Italic'),
    local('TitilliumWeb-ExtraLightItalic'),
    url(#{$theme-fonts-path}/TitilliumWeb-ExtraLightItalic.ttf);
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 300;
  src:
    local('TitilliumWeb Light'),
    local('TitilliumWeb-Light'),
    url(#{$theme-fonts-path}/TitilliumWeb-Light.ttf);
}

@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 300;
  src:
    local('TitilliumWeb Light Italic'),
    local('TitilliumWeb-LightItalic'),
    url(#{$theme-fonts-path}/TitilliumWeb-LightItalic.ttf);
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 400;
  src:
    local('TitilliumWeb Regular'),
    local('TitilliumWeb-Regular'),
    url(#{$theme-fonts-path}/TitilliumWeb-Regular.ttf);
}

@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 400;
  src:
    local('TitilliumWeb Regular Italic'),
    local('TitilliumWeb-RegularItalic'),
    url(#{$theme-fonts-path}/TitilliumWeb-RegularItalic.ttf);
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 500;
  src:
    local('TitilliumWeb SemiBold'),
    local('TitilliumWeb-SemiBold'),
    url(#{$theme-fonts-path}/TitilliumWeb-SemiBold.ttf);
}

@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 500;
  src:
    local('TitilliumWeb SemiBoldItalic'),
    local('TitilliumWeb-SemiBoldItalic'),
    url(#{$theme-fonts-path}/TitilliumWeb-SemiBoldItalic.ttf);
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 700;
  src:
    local('TitilliumWeb Bold'),
    local('TitilliumWeb-Bold'),
    url(#{$theme-fonts-path}/TitilliumWeb-Bold.ttf);
}

@font-face {
  font-family: 'Titillium Web';
  font-style: italic;
  font-weight: 700;
  src:
    local('TitilliumWeb BoldItalic'),
    local('TitilliumWeb-BoldItalic'),
    url(#{$theme-fonts-path}/TitilliumWeb-BoldItalic.ttf);
}

@font-face {
  font-family: 'Titillium Web';
  font-style: normal;
  font-weight: 800;
  src:
    local('TitilliumWeb Black'),
    local('TitilliumWeb-Black'),
    url(#{$theme-fonts-path}/TitilliumWeb-Black.ttf);
}
