$slick-font-path: '/themes/distribuidor/assets/fonts/';
$slick-font-family: 'slick';
$slick-loader-path: '/themes/distribuidor/assets/img/';
$slick-arrow-color: $black;
$slick-dot-color: $black;
$slick-dot-color-active: $slick-dot-color;
$slick-prev-character: '\2190';
$slick-next-character: '\2192';
$slick-dot-character: '\2022';
$slick-dot-size: 1rem;
$slick-opacity-default: .75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: .25;

@import '~slick-carousel/slick/slick-theme';
@import '~slick-carousel/slick/slick';
