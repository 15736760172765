.m-carousel.slick-slider {
  margin-bottom: 15px;
  padding-bottom: 30px;
  width: 100%;

  .slick-next,
  .slick-prev {
    z-index: 1;
    height: 40px;
    width: 40px;

    &::before {
      font-size: 2.5rem;
    }
  }

  .slick-prev {
    left: 1vw;
  }

  .slick-next {
    right: 1vw;
  }

  .slick-dots {
    bottom: 0;
  }

  .slick-slide {
    position: relative;

    img {
      height: 350px;
      object-fit: cover;
      width: 100%;

      @include media-breakpoint-up(md) {
        height: 500px;
      }
    }

    p {
      background-color: rgba($black, .5);
      border-radius: 5px;
      bottom: 10px;
      color: $white;
      left: 0;
      padding: 10px;
      position: absolute;
      right: 0;
      text-align: center;

      @include media-breakpoint-up(md) {
        line-height: 1.3;
        font-size: 1.5rem;
        padding: 15px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 30px;
  }
}
