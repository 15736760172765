.m-post-sidenav {
  .search {
    display: none;
  }

  .widget {
    h3 {
      border-bottom: 1px solid $endeavour;
      color: $endeavour;
      font-size: 1.125rem;
      margin-bottom: 20px;
      margin-top: 20px;
      padding-bottom: 6px;
    }

    a {
      color: $shuttle-gray;
      font-size: .875rem;

      &:hover {
        opacity: .5;
        text-decoration: none;
      }
    }
  }

  .tags.widget {
    ul {
      display: flex;
      flex-flow: wrap;
    }

    li {
      margin: 10px 2px;
    }

    a {
      background-color: $oslo-gray;
      border-radius: 4px;
      color: $white;
      padding: 5px;
    }
  }

  .popular-blogpost-container {
    display: flex;
    margin: 10px 0;
  }

  .popular-blogpost-image {
    height: 90px;
    min-width: 140px;
    margin-right: 10px;

    img {
      height: 100%;
      min-width: 100%;
    }
  }

  .category {
    margin-bottom: 12px;
  }

  .author {
    display: inline-flex;
    align-items: center;
    margin-right: 5px;
  }

  .calendar,
  .comments,
  .views {
    display: inline-flex;
    align-items: center;
    margin-right: 5px;

    &::before {
      background-position: bottom;
      background-repeat: no-repeat;
      content: "";
      display: inline-block;
      height: 15px;
      width: 15px;
    }
  }

  .blogpost-info {
    display: flex;
    justify-content: flex-start;
  }

  .blogpost-info,
  a.author-name {
    font-size: .5625rem;
  }

  .calendar::before {
    background-image: url("../img/calendar_icon.svg");
    margin-right: 10px;
  }

  .comments {
    margin-left: 10px;

    &::before {
      background-image: url("../img/comments_icon.svg");
      margin-right: 10px;
    }
  }

  .views::before {
    background-image: url("../img/vistas_icon.svg");
    background-position: center;
    margin-right: 5px;
    width: 20px;
  }
}
