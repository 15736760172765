.m-featured-posts {
  a:hover {
    opacity: .6;
    text-decoration: none;
  }

  .recent-posts {
    margin-bottom: 25px;
  }

  .img-responsive {
    max-height: 519px;
    width: 100%;
  }

  .recent-post-title a {
    color: $trout;
    font-size: 1.3125rem;
  }

  .tag a {
    color: $cornflower-blue;
  }

  .blogpost-info,
  .blog-description {
    display: none;
  }

  .view-more-link {
    a {
      align-items: center;
      color: $endeavour;
      display: flex;

      &::after {
        background-image: url("../img/vermas_icon.svg");
        background-repeat: no-repeat;
        content: "";
        height: 15px;
        margin-left: 7px;
        width: 15px;
      }
    }

    .arrow-icon {
      font-size: 1.125rem;
    }
  }

  .content {
    background-color: $white;
    margin-bottom: 0;
    padding: 10px;
  }

  .latest-post {
    border-bottom: 1px dashed $oslo-gray;

    .newest-item {
      padding-bottom: 15px;
    }

    .avatar {
      height: 16px;
      margin-bottom: 4px;
      margin-right: 3px;
    }

    .blog-description {
      display: block;
    }

    .blogpost-info {
      display: block;
    }

    .tag {
      display: none;
    }

    .author,
    .date-time,
    .views {
      display: inline-block;
      margin-left: 5px;
    }

    .blog-post-info {
      color: $trout;

      &:hover {
        text-decoration: none;
      }
    }

    .author {
      display: inline-flex;
    }

    .calendar,
    .comments,
    .views {
      &::before {
        background-position: bottom;
        background-repeat: no-repeat;
        content: "";
        display: inline-block;
        height: 15px;
        width: 15px;
      }
    }

    .calendar::before {
      background-image: url("../img/calendar_icon.svg");
      margin-right: 10px;
    }

    .comments {
      margin-left: 10px;

      &::before {
        background-image: url("../img/comments_icon.svg");
        margin-right: 10px;
      }
    }

    .views::before {
      background-image: url("../img/vistas_icon.svg");
      margin-right: 5px;
      width: 20px;
    }

    .content {
      align-items: center;
      background-color: transparent;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    .view-more-link {
      text-align: right;
      width: 100%;

      a {
        justify-content: flex-end;
      }
    }
  }
}
