i {
  background-size: contain;
  display: inline-block;
  min-height: 20px;
  min-width: 20px;
}

.icon-search {
  background-image: url(#{$theme-assets-path}/img/search_icon.svg);
}

.icon-footer-facebook {
  background-image: url(#{$theme-assets-path}/img/footer_facebook.svg);
}

.icon-footer-twitter {
  background-image: url(#{$theme-assets-path}/img/footer_twitter.svg);
}

.icon-footer-whatsapp {
  background-image: url(#{$theme-assets-path}/img/footer_whatsapp.svg);
}

.icon-footer-youtube {
  background-image: url(#{$theme-assets-path}/img/footer_youtube.svg);
}
