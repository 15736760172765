.m-footer {
  align-items: center;
  background-color: $cornflower-blue;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 30px 20px;
  width: 100%;

  a {
    font-weight: $fw-semibold;
  }

  .logos,
  .important-links {
    @include media-breakpoint-up(md) {
      align-items: flex-start;
      flex-basis: 50%;
    }
  }

  .logos {
    margin-bottom: 30px;
    text-align: center;

    > .privacy-link {
      display: block;
      margin-top: 30px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }

  .important-links {
    align-items: center;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      align-items: flex-end;
    }
  }

  .site-links,
  .social-media {
    margin-bottom: 0;
  }

  .site-links {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    .item {
      flex-basis: 33%;
    }

    .footer-links-column {
      align-items: center;
      display: flex;
      flex-direction: column;
      line-height: 2;
      text-align: right;

      @include media-breakpoint-up(md) {
        height: 100px;
        align-items: flex-end;
      }
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    @include media-breakpoint-up(xl) {
      width: 80%;
    }
  }

  .cta {
    color: $white;
    margin: 30px 0 10px;
    text-align: center;
  }

  .social-media {
    float: right;

    i {
      height: 24px;
      width: 24px;
    }

    > li {
      display: inline-block;

      &:not(:first-child) {
        margin-left: 40px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    align-items: flex-start;
    flex-direction: row;
    padding: 45px 3%;
  }
}
