.m-header-menu {
  @include flex-center-justify;

  font-size: 1rem;
  height: 119px;
  width: 100%;

  .brands,
  .actions {
    align-items: center;
    display: flex;
    flex-grow: 1;
    justify-content: center;

    > *:not(:first-child) {
      margin: 0 0 0 15px;

      @include media-breakpoint-up(md) {
        margin: 0 0 0 70px;
      }
    }
  }

  .current-datetime,
  .my-profile-link {
    color: $white;
  }

  .my-profile-link {
    color: $white;
    font-weight: $fw-bold;
    text-transform: uppercase;

    @include media-breakpoint-up(md) {
      color: $manatee;
    }
  }

  .current-datetime {
    font-weight: $fw-semibold;

    @include media-breakpoint-up(md) {
      color: $cornflower-blue;
    }
  }

  .mobile-header-logo {
    display: block;
    width: 100%;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .desktop-header-logo {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
