// Colors
$black: black;
$silver: #ccc;
$alabaster: #f8f8f8;
$white: white;
$neon-carrot: #ff8d2c;
$bermuda: #74e0aa;
$sherpa-blue: #005151;
$malibu: #6de3fd;
$oslo-gray: #7c878e;
$endeavour: #005eb8;
$shuttle-gray: #626d7f;
$cornflower-blue: #003a70;
$trout: #4d4f5c;
$manatee: #919192;
$eminence: #582c83;
$radical-red: #f72867;
$facebook-blue: #20376d;
$whatsapp-green: #7ec85d;

// Reusable variables
$theme-assets-path: '/themes/distribuidor/assets';

// Typography
$font-family: 'Titillium Web', 'Verdana', sans-serif;

// Font weights
$fw-extra-light: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-semibold: 500;
$fw-bold: 700;
$fw-black: 800;

//svg images
$white-hamburger-menu-icon: url('data:image/svg+xml,%3csvg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"%3e%3cpath stroke="#{$white}" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/%3e%3c/svg%3e');
