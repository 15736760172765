.m-hero {
  margin-bottom: 15px;
  width: 100%;

  .hero-image {
    height: 185px;
    object-fit: cover;
    width: 100%;

    @include media-breakpoint-up(md) {
      height: 305px;
    }
  }

  .hero-content {
    background-color: $cornflower-blue;
    display: flex;
    flex-direction: column;

    @include media-breakpoint-up(md) {
      align-items: center;
      flex-direction: row;
      height: 124px;
      justify-content: space-around;
    }
  }

  .cta {
    align-items: center;
    color: $white;
    display: flex;
    font-size: 1rem;
    font-weight: $fw-regular;
    margin: 15px;
    height: 100%;

    @include media-breakpoint-up(md) {
      line-height: 1.3;
      font-size: 1.5rem;
      margin: 0;
      max-width: 600px;
      padding: 15px;
    }
  }

  .ribbon {
    align-items: center;
    align-self: flex-end;
    background-position: bottom;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    height: 85px;
    padding-top: 30px;
    width: 200px;

    > p {
      color: $white;
      font-size: .875rem;
      text-align: center;
    }

    &.blue {
      background-image: url('../img/ribbons/blue.svg');
    }

    &.light-blue {
      background-image: url('../img/ribbons/light-blue.svg');
    }

    &.green {
      background-image: url('../img/ribbons/green.svg');
    }

    &.purple {
      background-image: url('../img/ribbons/purple.svg');
    }
  }

  @include media-breakpoint-up(md) {
    margin-bottom: 30px;
  }
}
