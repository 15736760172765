.m-alert {
  align-items: center;
  color: $white;
  display: flex;
  flex-direction: column;
  left: 0;
  height: 60px;
  overflow-y: auto;
  padding: 10px 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1;

  &.multiple {
    @include media-breakpoint-up(sm) {
      padding: 10px 0;
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  .title {
    font-weight: $fw-bold;

    &.single {
      margin-right: 10px;
    }

    &.multiple {
      display: block;
    }
  }

  .list {
    font-weight: $fw-light;
  }

  &.alert-success {
    color: $endeavour;
    background-color: $success;
  }

  &.alert-info {
    color: $endeavour;
    background-color: $info;
  }

  &.alert-warning {
    background-color: $warning;
  }

  &.alert-danger {
    background-color: $danger;
  }

  @include media-breakpoint-up(sm) {
    flex-direction: row;
    font-size: 1.625rem;
    justify-content: center;
    padding: 0;
  }
}
