ol,
ul,
dl {
  margin-bottom: 0;
}

.nav-link {
  font-size: 1.125rem;
}

.navbar-toggler-icon {
  background-image: str-replace(url('data:image/svg+xml,%3csvg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg"%3e%3cpath stroke="#{$shuttle-gray}" stroke-width="2" stroke-linecap="round" stroke-miterlimit="10" d="M4 7h22M4 15h22M4 23h22"/%3e%3c/svg%3e'), '#', '%23');
}

input[disabled] {
  cursor: not-allowed;
}
