.m-profile {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding: 20px 10px;

  form:not(:last-child) {
    margin-bottom: 30px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 90px;
    }
  }

  .content {
    flex-grow: 1;
  }

  .title {
    color: $endeavour;
    border-bottom: 1px solid $endeavour;
    font-size: 1.125rem;
    margin-bottom: 30px;
    padding-bottom: 10px;
    width: 100%;

    @include media-breakpoint-up(lg) {
      margin-bottom: 55px;
      padding-bottom: 15px;
      width: 385px;
    }
  }

  .checkbox-row {
    @extend .row;

    &:not(:first-child) {
      margin-bottom: 25px;
    }
  }

  .custom-control-label {
    @include media-breakpoint-up(lg) {
      padding-left: 40px;
    }
  }

  .submit-btn {
    min-width: 145px;

    &:not(:first-child) {
      margin-left: 45px;
    }
  }

  .m-avatar-file {
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
      margin-bottom: 50px;
    }
  }

  @include media-breakpoint-up(lg) {
    padding: 100px 0;
  }
}
