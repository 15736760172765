$nav-bg-colors: $cornflower-blue, $endeavour, $sherpa-blue, $eminence;
$nav-bg-colors-length: length($nav-bg-colors);

.m-navbar {
  transition: width .3s;
  width: 0;

  .navbar-nav {
    width: 100%;
  }

  .navbar-content {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
      width: 100%;
    }
  }

  .social-media-links {
    display: flex;
    align-items: center;
    border-top: 1px solid $white;
    flex-direction: column;
    justify-content: center;
    margin-top: 15px;
    padding: 20px 25px;

    .cta {
      color: $white;
      text-align: center;
    }

    .social-media {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      width: 100%;
    }

    i {
      background-size: cover;
      height: 30px;
      width: 30px;
    }

    @include media-breakpoint-up(md) {
      display: none;
    }
  }

  .navbar-toggler:focus {
    outline: none;
  }

  .nav-item {
    flex-basis: 25%;
    text-align: center;
    transition: background-color 200ms ease-in-out;

    @for $i from 1 through $nav-bg-colors-length {
      &:nth-of-type(#{$nav-bg-colors-length}n+#{$i}) {
        background-color: nth($nav-bg-colors, $i);
      }

      &:nth-of-type(#{$nav-bg-colors-length}n+#{$i}):hover {
        background-color: darken(nth($nav-bg-colors, $i), 5%);
      }
    }
  }

  .nav-link {
    color: $white;
    line-height: 32px;
    min-height: 48px;
  }

  @include media-breakpoint-up(md) {
    padding: 0;
    width: 100%;
  }
}
