.m-button {
  @extend .btn;

  &.endeavour {
    background-color: $malibu;
    color: $endeavour;

    &:hover {
      background-color: darken($malibu, 7%);
    }
  }
}

.m-button--block {
  @extend .btn;
  @extend .btn-block;
}
