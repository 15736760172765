.m-single-blogpost {
  margin-top: 70px;

  .item-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .featured-blog-image {
    margin-bottom: 35px;
  }

  .article-container {
    max-width: 845px;

    img {
      max-width: 100%;
    }

    a {
      color: #003a70;

      &:hover {
        color: #005eb8;
      }
    }
  }

  .author {
    display: inline-flex;
  }

  .avatar {
    height: 16px;
    margin-bottom: 4px;
    margin-right: 3px;
  }

  .blogpost-info {
    color: $trout;

    &:hover {
      text-decoration: none;
    }
  }

  .calendar,
  .comments {
    &::before {
      background-position: bottom;
      background-repeat: no-repeat;
      content: "";
      display: inline-block;
      height: 15px;
      width: 15px;
    }
  }

  .calendar::before {
    background-image: url("../img/calendar_icon.svg");
    margin: 0 5px;
  }

  .comments {
    display: none;

    &::before {
      background-image: url("../img/comments_icon.svg");
      margin-right: 10px;
    }
  }

  .img-responsive {
    width: 100%;
  }

  .blog-post-title {
    color: $endeavour;
    font-weight: 400;
  }

  .blog-category {
    color: $cornflower-blue;
    display: block;
    font-size: .9375rem;
    margin-bottom: 25px;
  }

  .author-img {
    display: none;
  }

  .blog-basic-info {
    display: block;
    margin-bottom: 50px;
  }

  .blogpost-tags {
    display: none;
  }

  .blopost-info-socialm {
    display: flex;
    justify-content: flex-end;
  }

  .blogpost-link {
    margin: 0 10px;

    &.facebook {
      margin-left: 50px;

      @include media-breakpoint-down(sm) {
        margin-left: 15px;
      }
    }

    &.bookmark {
      margin-right: 0;
    }

    @include media-breakpoint-down(sm) {
      margin: 0 3px;
    }
  }

  .blog-username {
    margin-right: 15px;
  }

  .submit-comment-button {
    margin-bottom: 30px;
  }

  .facebook-icon {
    background-image: url("../img/facebook_icon_share.svg");
    background-position: center bottom;
    content: '';
    display: inline-block;
    height: 17px;
    width: 8px;
  }

  .whatsapp-icon {
    background-image: url("../img/whatsapp_icon_share.svg");
    background-position: center;
    content: '';
    display: inline-block;
    height: 18px;
    margin-left: 10px;
    width: 18px;
  }

  .bookmark-icon {
    background-image: url("../img/bookmark_icon_share.svg");
    background-position: center;
    content: '';
    display: inline-block;
    height: 18px;
    width: 15px;

    @include media-breakpoint-down(sm) {
      background-repeat: no-repeat;
      height: 19px;
      width: 16px;
    }
  }

  .section-title {
    border-bottom: 1px solid $cornflower-blue;
    color: $cornflower-blue;
    font-size: 1.125rem;
    margin-bottom: 20px;
    padding-bottom: 10px;
    text-transform: uppercase;
  }

  .basic-comment-info-container {
    display: flex;
    margin-bottom: 20px;
  }

  .comment-information {
    border: 1px solid $trout;
    border-radius: 5px;
    display: block;
    margin: 25px 0;
    padding: 30px;
  }

  .author-date-container {
    display: flex;
    flex-direction: column;
  }

  .avatar-comment-container {
    height: 40px;
    margin-right: 40px;
    width: 40px;
  }

  .avatar-comment {
    height: 100%;
    width: 100%;
  }

  .related-content {
    margin: 50px 30px 30px;
  }

  .additional-information-container {
    display: flex;
    width: 100%;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
  }

  .group-container {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 133px;
    justify-content: center;
    text-decoration: none;
    width: 33.3%;

    &.facebook {
      background-color: $facebook-blue;
      margin-right: 10px;

      @include media-breakpoint-down(sm) {
        margin: 0;
      }
    }

    &.whatsapp {
      background-color: $whatsapp-green;
      margin: 0 10px;

      @include media-breakpoint-down(sm) {
        margin: 0;
      }
    }

    &.profile {
      background-image: url('../img/group-background-image.png');
      margin-left: 10px;
      position: relative;

      @include media-breakpoint-down(sm) {
        margin: 0;
      }
    }

    @include media-breakpoint-down(sm) {
      width: 100%;
    }
  }

  .facebook-group-icon {
    background-image: url('../img/facebook_group_icon.svg');
    height: 65px;
    margin-right: 10px;
    width: 65px;

    @include media-breakpoint-down(sm) {
      width: 64px;
    }
  }

  .whatsapp-group-icon {
    background-image: url('../img/whatsapp_group_icon.svg');
    height: 65px;
    margin-right: 10px;
    width: 67px;
  }

  .group-text {
    color: $white;
    display: flex;
    flex-direction: column;
    text-align: center;
    text-transform: uppercase;
    z-index: 1;

    .bold-text {
      font-size: 1.8125rem;
    }
  }

  .related-blog-content {
    margin-top: 50px;

    .popular-blogpost-information {
      background-color: $white;
      min-height: 125px;
      padding: 28px 25px;
    }

    .blogpost-title {
      color: $trout;
    }

    .blogpost-image {
      width: 100%;
    }

    .view-more-link {
      color: $facebook-blue;

      &::after {
        background-image: url("../img/vermas_icon.svg");
        background-repeat: no-repeat;
        content: "";
        display: inline-block;
        height: 15px;
        margin-left: 7px;
        width: 15px;
      }
    }
  }

  .overlay-color {
    background-color: $endeavour;
    content: '';
    height: 100%;
    opacity: .75;
    position: absolute;
    width: 100%;
  }
}
