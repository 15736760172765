.l-base {
  min-height: 100vh;
  width: 100%;

  a.link {
    color: $manatee;

    &:hover,
    &:focus,
    &[aria-expanded='true'] {
      color: $endeavour;
    }
  }

  .content {
    margin-bottom: 25px;

    @include media-breakpoint-up(md) {
      margin-bottom: 75px;
    }
  }

  &.fixed {
    overflow: hidden;
    position: fixed;

    @include media-breakpoint-up(md) {
      overflow: auto;
      position: relative;
    }
  }
}
