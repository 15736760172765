.m-login-form {
  ::placeholder {
    color: $alabaster;
  }

  a,
  p {
    font-size: .8125rem;
  }

  a,
  .control-label,
  .welcome,
  [type='submit'] {
    font-weight: $fw-light;
  }

  .form-control,
  [type='submit'] {
    height: 49px;
  }

  .logos {
    text-align: center;
  }

  .login-form {
    margin-top: 20px;
    padding: 0 20px;

    @include media-breakpoint-up(sm) {
      justify-content: space-between;
      margin-top: 40px;
      padding: 0 40px;
    }
  }

  .welcome {
    margin-top: 20px;
  }

  .form-group:not(:first-child) {
    margin-top: 1.25rem;
  }

  .form-control {
    background-color: transparent;
    border: 1px solid $white;
    color: $white;
  }

  .submit-group {
    align-items: center;
    display: flex;
    flex-direction: column;

    > [type='submit'] {
      font-size: .9375rem;
      width: 168px;
    }
  }

  .forgot-password-link {
    margin-top: 20px;
  }

  .register-links {
    align-items: center;
    flex-direction: column;
    margin-top: 50px;
  }

  .register-link {
    color: $malibu;
  }
}
