.m-avatar-file {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;

  .image-container {
    position: relative;
  }

  .image,
  .image-destroy {
    border: 1px solid $oslo-gray;
    border-radius: 50%;
  }

  .image {
    background-color: $oslo-gray;
    height: 100px;
    max-width: 100%;
    object-fit: cover;
    width: 100px;
  }

  .actions {
    max-width: 180px;
    text-align: right;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  .image-destroy {
    align-items: center;
    background-color: $endeavour;
    color: $white;
    cursor: pointer;
    display: flex;
    height: 26px;
    justify-content: center;
    position: absolute;
    right: -10px;
    top: 10px;
    width: 28px;

    > span {
      font-size: .875rem;
      font-weight: $fw-bold;
      line-height: 1;
    }
  }

  .file-input-label {
    cursor: pointer;
    min-width: 138px;
  }

  .file-input {
    @extend .sr-only;
  }

  .file-info {
    color: $oslo-gray;
    font-size: .8125rem;
  }

  @include media-breakpoint-up(md) {
    max-width: 400px;
  }
}
