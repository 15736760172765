.m-menu-dropdown {
  border: 0;
  border-radius: 0;
  box-shadow: 1px 1px 5px 1px rgba($black, .5);
  padding: 0;
  width: 280px;

  .m-button {
    font-size: 1rem;
  }

  .avatar {
    background-color: lighten($endeavour, 10%);
    border: 2px solid $white;
    border-radius: 50%;
    margin-bottom: 20px;
    height: 70px;
    width: 70px;
  }

  .header {
    align-items: center;
    background-color: $endeavour;
    color: $white;
    display: flex;
    flex-direction: column;
    font-size: .875rem;
    justify-content: center;
    min-height: 110px;

    > p {
      text-align: center;
    }
  }

  .footer {
    display: flex;
    height: 50px;

    > a {
      flex-grow: 1;
      font-weight: $fw-semibold;
      line-height: 36px;
    }
  }

  @include media-breakpoint-up(md) {
    left: 50%;
    transform: translateX(-50%);
  }
}
