.m-overlay {
  display: none;

  &.active {
    background-color: rgba($black, .7);
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;

    @include media-breakpoint-up(md) {
      display: none;
    }
  }
}
