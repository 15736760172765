.m-blog-hero {
  background-color: $cornflower-blue;
  color: $white;
  display: flex;
  height: 200px;
  margin-bottom: 60px;
  width: 100%;

  .hero-content,
  .hero-image {
    height: 100%;
  }

  .hero-content {
    align-items: center;
    display: flex;
    flex-basis: 52%;
    justify-content: flex-end;
    padding-right: 30px;
    position: relative;

    &::after {
      background-color: $malibu;
      content: '';
      height: 100%;
      position: absolute;
      right: -13px;
      top: 0;
      transform: skew(-6deg);
      width: 26px;
    }

    @include media-breakpoint-up(md) {
      padding-right: 10%;
    }
  }

  .hero-image {
    flex-basis: 48%;
    max-width: 48%;
    object-fit: cover;
  }

  @include media-breakpoint-up(md) {
    height: 265px;
  }
}
