$primary: $endeavour;
$secondary: $oslo-gray;
$success: $bermuda;
$info: $malibu;
$warning: $neon-carrot;
$danger: $radical-red;

// Body
$body-bg: $alabaster;
$body-color: $trout;

// Links
//
// Style anchor elements.
$link-color: $white;

$grid-gutter-width: 60px;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 0;

// Typography
$font-family-base: $font-family;
$font-size-base: 1.125rem;

// Alerts
$alert-border-radius: 0;
$alert-border-width: 0;

// Forms
$label-margin-bottom: .625rem;

$input-padding-y: 1.125rem;
$input-padding-x: 1.5rem;
$input-font-size: 1rem;

$input-bg: transparent;
$input-disabled-bg: $silver;

$input-color: $black;
$input-border-color: $silver;
$input-border-with: 1px;

$input-border-radius: 4px;

$input-placeholder-color: $silver;

$custom-control-indicator-size: 1.625rem;
$custom-control-indicator-border-color: $silver;

$custom-control-indicator-disabled-bg: $alabaster;
$custom-control-label-disabled-color: $silver;

$custom-checkbox-indicator-border-radius: 50%;

// Pagination
$pagination-color: $primary;
$pagination-hover-color: $primary;
